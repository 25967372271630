// Styles

// Main styles

@import './app.scss';

// Hyper Icons
@import './icons.scss';


.btn-danger {
    color: #fff;
    background-color: #FF5C39;
    border-color: #FF5C39;
}

.btn-danger:hover {
    color: #fff;
    background-color: #FF5C39;
    border-color: #FF5C39;
}

.btn-primary {
    color: #000000;
    background-color: #BAFF2A;
    border-color: #BAFF2A;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open>.dropdown-toggle.btn-primary {
    color: #000000;
    background-color: #BAFF2A;
    border-color: #BAFF2A;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #000000;
    background-color: #BAFF2A;
}

/* multi select */
.css-1rhbuit-multiValue react-select__multi-value {
    background-color: red;
}


.grid-structure.grid-structure-newsett .grid-container {
    padding: 10px 10px;
}

.profile-picture {
    position: relative;
}

.profile-picture-icon {
    background-color: #DADADA;
    border-radius: 50%;
    padding: 3px 6px;
    width: 36px !important;
    height: 36px !important;
}

.radiostyle {
    input {
        position: absolute;
        visibility: hidden;
    }

    // input:checked[type=radio] {}

    input[type=radio]~label,
    input[type=radio]:checked~label {
        background-color: #fff;
        padding: 2px 4px;
        border-radius: 4px;
        border: 1px solid #DEDEDE;
        font-size: 14px;
        line-height: 19px;
        font-weight: 700;
        color: #000000;
    }



    input[type=radio]:checked~label {
        background-color: #BAFF2A;
    }

    // .form-radio-label {
    //     background-color: #BAFF2A;
    //     padding: 2px 4px;
    // }

}

.prostylecol {
    justify-content: center;
    align-items: center;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: auto;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        "prostylecol-1 prostylecol-2 prostylecol-3";
}

.prostylecol-1 {
    grid-area: prostylecol-1;
}

.prostylecol-2 {
    grid-area: prostylecol-2;
    padding-left: 3px;
    padding-right: 3px;
}

.prostylecol-3 {
    grid-area: prostylecol-3;
}

.form-control {
    color: #000000 !important;
}


.gray-icon-colour {
    color: #ccc !important;
}

.fnt-11 {
    font-size: 17px !important;
}

.page-title-box .page-title {
    color: #000000;
    font-weight: 700;
}

.react-select__multi-value .react-select__multi-value__label {
    color: #000000;
    background-color: #BAFF2A;
}

.image-upload>input {
    display: none;
}

.image-upload i {
    width: 80px;
    cursor: pointer;
}

.badge {
    padding: 0.35em 0.4em;
}

.breadcrumb {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
}

.breadcrumb-item a {
    color: #000000;
}

/* multi select */

/* checkbox badge */

.badge-checkboxes .checkbox input[type="checkbox"],
.badge-checkboxes label.checkbox-inline input[type="checkbox"] {
    /*  Hide the checkbox, but keeps tabbing to it possible. */
    position: absolute;
    clip: rect(0 0 0 0);
}

.badge-checkboxes .checkbox label,
.badge-checkboxes label.checkbox-inline {
    padding-left: 0;
    /* Remove space normally used for the checkbox */
}

.badge-checkboxes .checkbox input[type="radio"]:checked:focus+.badge,
.badge-checkboxes label.checkbox-inline input[type="radio"]:checked:focus+.badge {
    box-shadow: 0 0 2pt 1pt #333;
    background-color: #BAFF2A;
    color: #000000;
    /* Outline when checkbox is focused/tabbed to */
}

.badge-checkboxes .checkbox input[type="radio"]:focus+.badge,
.badge-checkboxes label.checkbox-inline input[type="radio"]:focus+.badge {
    box-shadow: 0 0 2pt 1pt #999;
    /* Outline when checkbox is focused/tabbed to */
}

.badge-checkboxes .checkbox input[type="radio"]+.badge,
.badge-checkboxes label.checkbox-inline input[type="radio"]+.badge {
    border: 1px solid #999;
    /* Add outline to badge */

    /* Make text in badge not selectable */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.badge-checkboxes .checkbox input[type="radio"]:disabled+.badge,
.badge-checkboxes label.checkbox-inline input[type="radio"]:disabled+.badge {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
    opacity: 0.5;
}

/* Remove badge background-color and set text color for not checked options */
.badge-checkboxes .checkbox input[type="radio"]:not(:checked)+.badge,
.badge-checkboxes label.checkbox-inline input[type="radio"]:not(:checked)+.badge {
    background-color: #DADADA;
    color: #000000;
}

/*The following css only required for Bootstrap <= 3.1.0 */
.badge-checkboxes .radio {
    padding-left: 0;
    /* Remove space normally used for the checkbox */
}

.badge-checkboxes .disabled label,
.badge-checkboxes label.checkbox-inline.disabled {
    cursor: not-allowed
}

/* The following CSS not required for the badge styled checkboxes: */
section+section {
    margin-top: 20px;
}

label+.checkbox {
    margin-top: 0;
}

/* checkbox badge */


/* SAT / SAM work 31 - 10 - 2022 */

.sidebar-outer {
    .simplebar-content {
        padding: 0 !important;
    }
}

.btn-white {
    background-color: #fff;
}

.back-transprant {
    /* background-color: transparent;*/
    background-color: #ededf1 !important;
}

.back-white {
    background-color: #fff;
}

.btn-secondary2 {
    background-color: #EBEBEB;
}

.select-bold {
    font-weight: bold;
}

.react-responsive-modal-modal {
    width: 500px;
}

.model-new-right {
    width: 100%;
    max-width: 500px;
    right: 0;
    top: 0;
    left: auto;
    bottom: 0;
    height: 100%;
}

.gray-input-icon {
    background-color: #F3F3F3;

    input,
    select {
        background-color: #F3F3F3;
    }

    position: relative;

    i {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 22px;
        font-weight: bold;
    }
}

.round-icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 4px;
    position: relative;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.round-icon-active {
    background-color: #BAFF2A;
    color: #000000;
}

.round-icon-de-active {
    background-color: #ED725D;
}

.switch-adjust {
    .form-switch .form-check-input {
        width: 2.5em;
    }

    .form-check-input:checked {
        height: 1.5em;
        background-color: #BAFF2A;
    }

    .form-check-input {
        height: 1.5em;
        background-color: #EBEBEB;
    }
}

$form-switch-checked-bg-image_sat: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23000'/></svg>");
//$form-switch-checked-bg-image_sat_uncheck:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23EBEBEB'/></svg>");

.form-switch .form-check-input:checked,
.form-switch .form-check-input {
    //background-position: right center;
    background-image: escape-svg($form-switch-checked-bg-image_sat),
    var(--#{$variable-prefix}gradient);
}


.gray-back {
    background-color: #EBEBEB;
    border-color: #EBEBEB;
}

.big-icon-top {
    position: relative;
}

.big-icon-top-big {
    background-color: #BAFF2A;
    width: 50px;
    height: 50px;
    border-radius: 20%;
    position: relative;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 22px;
        font-weight: bold;
        color: #000000;

    }
}

.refresh-new {
    position: absolute;
    bottom: -10px;
    right: -4px;
    font-size: 17px;
    font-weight: bold;
    color: #000000;
}

.live-ofline-width {
    min-width: 75px;
}

.select-color-green-red option {
    color: #009F2D;
}

.select-color-green-red option:checked {
    color: red;
}

.select-color-green-red {
    //color: blue;
}

.bot-button-position-main-div {
    display: flex;
    flex-direction: column;
}

.height-100 {
    height: 100%;
}

.select-green {
    font-size: 14px !important;
    color: green !important;
}

.select-gray {
    font-size: 14px !important;
    color: gray !important;
}

.select-red {
    font-size: 14px !important;
    color: red !important;
}

.select-number-width {
    min-width: 55px;
    max-width: 65px;
}

.feature-ico-hw img {
    height: 48px;
    width: 48px;
}

.feature-ico-hw-def img {
    border: 1px solid #ccc;
    height: 48px;
    width: 48px;
    border-radius: 4px;
}

.fr_serach {
    position: absolute;
    right: 24px;
    top: 30px;
}

.logo-sm img {

    width: 96%;
    max-width: 240px;
    object-fit: contain;

}

/* SAT / SAM work 31 - 10 - 2022 */